var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",staticClass:"col-xxs-20",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.i18n)?_c('div',{staticClass:"my-commune-add-user pb-xxl"},[_c('header',[(_vm.breadcrumbAdd)?_c('breadcrumb',{attrs:{"content":_vm.breadcrumbAdd}}):_vm._e(),_c('div',{staticClass:"page-header wrapper"},[_c('div',{staticClass:"page-header__title"},[_c('h1',{staticClass:"main-title",domProps:{"innerHTML":_vm._s(_vm.i18n.userGestion.addUser)}})])])],1),_c('div',{staticClass:"wrapper"},[(!_vm.isFetching)?_c('form',{ref:"form",staticClass:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit($event)}}},[_c('user-form',{attrs:{"model":_vm.inputsValues,"type":'addUser'}}),_c('br'),_c('CustomLink',{staticClass:"submitUser",class:{ 'is-loading': _vm.isLoading },attrs:{"content":{
            tag: 'button',
            label: _vm.i18n.userGestion.valider,
          },"disabled":_vm.disabledCondition || invalid || _vm.disableBtnSubmit,"modifiers":['btn', 'green', 'big', 'rectrounded'],"type":"button"},nativeOn:{"click":function($event){return _vm.onSubmit($event)}}}),_c('br'),_c('br'),(_vm.globalI18n)?_c('span',{staticClass:"small col-xxxs-20"},[_vm._v(" "+_vm._s(_vm.globalI18n.requiredFields)+" ")]):_vm._e(),(_vm.feedbackMessage)?_c('feedback-message',{staticClass:"mt-s",attrs:{"content":{
            type: _vm.hasError ? 'error' : 'success',
            htmltext: _vm.feedbackMessage,
          }}}):_vm._e()],1):_c('g-loader',{attrs:{"modifiers":['big']}})],1)]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }