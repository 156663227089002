



































































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
} from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'

import Message from '@/components/ui/Message.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import Header from '@/components/Header.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'

import UserForm from '@/components/commune/components/UserFormEdit.vue'
import userAccountRequest from '@/composables/userAccountRequest'

const { VUE_APP_MYCOMMUNE_DOMAIN } = process.env

export default defineComponent({
  name: 'my-commune-add-user',
  components: {
    Message,
    Breadcrumb,
    Header,
    UserForm,
    CustomLink,
    FeedbackMessage,
  },
  setup(_props, ctx) {
    const { $logger } = ctx.root
    const message = ref(true)
    const { chrome } = useState(['chrome'])
    const form = ref<HTMLFormElement>()
    const { i18n } = useState('commune', ['i18n'])
    const { currentLang } = useGetters(['currentLang'])
    const { FETCH_USERS } = useActions('commune', ['FETCH_USERS'])
    const breadcrumbAdd = [
      {
        label: i18n.value.breadcrumb.users,
        url: `/${currentLang.value}/users/`,
      },
      { label: i18n.value.breadcrumb.usersAdd, url: '' },
    ]

    const nom = ref('')
    const prenom = ref('')
    const email = ref('')
    const gsm = ref('')
    const departement = ref('')
    const fonction = ref('')
    const roles = ref([])
    const actif = ref(true)
    const disableBtnSubmit = ref(false)
    const feedbackWsError = ref('')
    const feedbackMessage = ref('')
    const hasError = ref(false)
    const isLoading = ref(false)
    const isFetching = ref(false)

    const inputsValues = reactive({
      Nom: nom.value,
      Prenom: prenom.value,
      Departement: departement.value,
      Fonction: fonction.value,
      Email: email.value,
      Tel: gsm.value,
      Actif: actif.value,
      Roles: roles.value,
    })

    ctx.root.$on('checkbox:selector:addUser', (roles: []) => {
      inputsValues.Roles = roles
    })

    onMounted(async () => {
      $logger.debug('Users:add:mounted')
      isFetching.value = true
      await FETCH_USERS()
        .then(resp => {
          isFetching.value = false
        })
        .catch(e => {
          isFetching.value = false
        })
    })

    const onSubmit = async () => {
      form.value!.reportValidity()
      $logger.debug('USER ADD')
      disableBtnSubmit.value = true
      isLoading.value = true
      const { res, error } = await userAccountRequest(
        'town/users',
        'post',
        inputsValues
      )
      // eslint-disable-next-line no-negated-condition
      if (!error) {
        const headers: Record<string, string> = {}
        headers.SessionId = res?.data.uid
        hasError.value = false
        feedbackMessage.value = i18n.value.error.addConfirm
        // const callback = getCallbackUrl('/reset/password/')
        const { currentLang } = useGetters(['currentLang'])

        const { error } = await userAccountRequest(
          `town/activate/sendmail?Callback=${
            VUE_APP_MYCOMMUNE_DOMAIN === 'http://mycommune.local:4000'
              ? 'https://macommune.myresa-qual.resa.be'
              : VUE_APP_MYCOMMUNE_DOMAIN
          }/${currentLang.value === 'de' ? 'de' : 'fr'}/signup/password/`,
          'post',
          { Email: res?.data.email },
          { headers }
        )
        // eslint-disable-next-line no-negated-condition
        if (error) {
          isLoading.value = false
          hasError.value = true
          feedbackMessage.value = error.response.data.message
          disableBtnSubmit.value = false
        } else {
          ctx.root.$router.push({ name: 'CommuneUsers' })
          isLoading.value = false
        }
      } else {
        disableBtnSubmit.value = false
        isLoading.value = false
        hasError.value = true
        feedbackMessage.value = error.response.data.message
      }
    }

    const disabledCondition = computed(() => {
      if (
        inputsValues.Nom &&
        inputsValues.Prenom &&
        inputsValues.Departement &&
        inputsValues.Email &&
        inputsValues.Roles.length > 0
      ) {
        return false
      }

      return true
    })

    const globalI18n = computed(() => chrome.value.data.i18n)

    return {
      message,
      i18n,
      breadcrumbAdd,
      ...useGetters('user', ['isAdmin']),
      inputsValues,
      onsubmit,
      disabledCondition,
      onSubmit,
      isLoading,
      form,
      disableBtnSubmit,
      feedbackWsError,
      hasError,
      feedbackMessage,
      globalI18n,
      isFetching,
    }
  },
})
